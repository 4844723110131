// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_38iwS{align-items:center;display:flex;flex-direction:row;margin-right:1.5rem;-webkit-text-decoration:underline;text-decoration:underline}.container_38iwS:hover{cursor:pointer}.container_38iwS:active,.container_38iwS:hover{-webkit-text-decoration:none;text-decoration:none}.icon_4LfzR,.label_D8BOc{display:flex}.label_D8BOc{margin-left:.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_38iwS",
	"icon": "icon_4LfzR",
	"label": "label_D8BOc"
};
module.exports = ___CSS_LOADER_EXPORT___;
